@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto+Condensed:wght@300;400;500&display=swap');

:root {
  --grey: #2F2D30;
  --yellow: #FFD176;
}

* {
  box-sizing: border-box;
}

/* Disabilita lo zoom su dispositivi mobili */
@media (max-width: 767px) {
  /* Puoi regolare il valore di "user-scalable" a seconda delle tue esigenze */
  body {
    touch-action: manipulation;
    user-scalable: no;
  }
}

.invert{
  display: none;
  filter: invert(1);
}

.Home {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

#header {
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 2rem .5rem 2rem;
  z-index: 100;
  background-color: white;
}

#header>img {
  height: 100px;
}

@media (max-width: 768px) {

  #header {
    background-color: white;
  }
  #header>img {
    height: 60px;
  }

  #header {
    padding: .5rem 1rem .5rem 1rem;
  }

  #opener{
    margin-top: 80px;
    height: calc(100vh - 240px) !important;
  }
}


#opener {
  
  width: 100%;
    height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  gap:2rem;
  padding: 2rem;
  background-image: url('/public/images/bg.png');
  background-size: contain;
  background-position: bottom;
  margin-bottom: -2px;
  background-repeat: no-repeat;
}

#selfiebot {
  max-width: 50%;
  height: 80%;



}

#selfiebot img {


   width: 100%;
   max-height: 100%;
   object-fit: contain;
  animation: scaleAnimation 2.5s ease-in-out; 
  filter: drop-shadow(5px 15px 15px #000000);
}

@keyframes scaleAnimation {
  0% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
  }
}

#opening-message {
  max-width: 55%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: flex-start;

  height: calc(100vh - 80px);
  z-index: 2; 
  text-transform: uppercase;
  /* white-space: pre-wrap;
 word-wrap: break-word;
 word-break: break-all */
}

#opening-message  h1{
  color: white;
  font-family: Poppins;
  font-size: 5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  margin-bottom: 1vw;
}
#opening-message h2{
  color: white;
  font-family: Poppins;
  font-size: 3vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  text-align: left;
  text-transform:none;
  margin: 0;
  margin-bottom: 1.5vw;
}

#opening-message p{
  color: white;
  font-size: 1.5vw;
  margin: 0;
  margin-bottom: 1.5vw;
}

@media (max-width: 1540px) {
  /* #selfiebot {
    right: 1rem;
  } */

}

@media (max-width: 768px) {
  
#opener {
  flex-wrap: wrap;
  flex-direction: column;
  gap: 0px;
}

  #opening-message {
    font-size: 20px;
   
    /* width: 50vw;
    padding-left: 2rem; */
  }

  #selfiebot {
    /* position: fixed;
    z-index: 0;
    right: 1rem;
    bottom: 6rem; */
  }

  #selfiebot img {
    object-fit: contain;
  }

  #bg svg {
    position: absolute;
    bottom: 0;
    width: 101%;
    height: 125px;
  }


}

/* -----
FEATURES
----- */

#features {
  display: block;
  width: 100%;
  height: 100%;

  background-color: #111;
  z-index: 10;
  padding-top: 100px;
}



@media (max-width: 768px) {
  #features {
   padding-bottom: 100px;
  }
}


/* -----
IMAGE GALLERY
----- */

#imageGallery {
  z-index: 11;
  width: 100%;
}

@media (max-width: 768px) {

}

/* -----
BOOKING FORM
----- */

#prenota {
  width: 100%;
  background-color: white;
  z-index: 10;
  padding-top:100px;
  border-bottom:  2px solid #4b516b;
}

#prenota iframe{
  height: 60vh;
  width: 100%;
  border: none;
  padding-bottom:50px;
}

h2{
  text-align:center;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
}
h4{
  text-align: center;
}



/* -----
CONTATTACI
----- */

#contattaci {
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 11;
  background-color: white;
  text-align: center;
}


/* -----
COPYRIGHT
----- */

#copyright {
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 11;
  background-color: white;
}

#copyright a {
color:#2F2D30;
text-decoration: none;
}

#copyright a:hover {
  text-decoration: underline;
  }